import { Button, Col, Form, Input, message, Row, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
function Reg() {
  const history = useHistory();

  const onFinish = (e) => {
    axios.post('login/reg', e).then((res) => {
      message.success('注册成功');
      history.push('/login');
    });
  };

  return (
    <>
      <Row
        type='flex'
        justify='center'
        align='middle'
        style={{ minHeight: '100vh' }}
      >
        <Col>
          <Typography.Title>注册</Typography.Title>
          <Form onFinish={onFinish} style={{ width: 320 }}>
            <Form.Item
              name='username'
              rules={[{ required: true, message: '请输入用户名' }]}
            >
              <Input prefix={<UserOutlined />} placeholder='用户名' />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[{ required: true, message: '请输入密码' }]}
            >
              <Input
                prefix={<LockOutlined />}
                type='password'
                placeholder='密码'
              />
            </Form.Item>
            <Form.Item
              name='perfix'
              rules={[{ required: true, message: '请输入前缀' }]}
            >
              <Input placeholder='前缀' />
            </Form.Item>
            <Form.Item
              name='team'
              rules={[{ required: true, message: '请输入团队' }]}
            >
              <Input placeholder='团队' />
            </Form.Item>
            <Form.Item
              name='super_password'
              rules={[{ required: true, message: '请输入超级密码' }]}
            >
              <Input placeholder='超级密码' />
            </Form.Item>
            <Form.Item>
              <Button block type='primary' htmlType='submit'>
                注册
              </Button>
            </Form.Item>
          </Form>
          <Link to='/login'>去登录</Link>
        </Col>
      </Row>
    </>
  );
}
export default Reg;
