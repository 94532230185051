import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Dropdown,
  Form,
  Input,
  InputNumber,
  List,
  Menu,
  message,
  Modal,
  Popover,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import BaseView from '../../components/BaseView';

const name = 'el';

function El() {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [listData, setListData] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    search: {},
    list: [],
    time: 0,
    loading: false,
    sort_name: '',
    sort_type: '',
  });
  const [historyData, setHistoryData] = useState([]);
  const [ipInfo, setIpInfo] = useState({});
  const [updateCount, setUpdateCount] = useState(0);

  useEffect(() => {
    try {
      const form_json = JSON.parse(localStorage.getItem(`${name}_form`));
      form.setFieldsValue(form_json);
    } catch (e) {}
    try {
      const _listData = JSON.parse(
        localStorage.getItem(`${name}_url_history1`)
      );
      setListData((e) => ({ ...e, ..._listData, time: 0 }));
      form2.setFieldsValue(_listData.search || {});
    } catch (e) {}
    axios.get(`${name}/getHistory`).then((res) => {
      setHistoryData(res);
    });
    axios.get(`/getUpdateCount`).then((res) => {
      setUpdateCount(res);
    });
  }, []);

  useEffect(() => {
    if (listData.time != 0) {
      setListData((e) => ({ ...e, loading: true }));
      handleLink();
    }

    const timer = setInterval(
      (time) => {
        if (new Date().valueOf() - time > 15000)
          setListData((e) => ({
            ...e,
            time: new Date().valueOf(),
          }));
      },
      15000,
      listData.time
    );

    return () => clearInterval(timer);
  }, [listData.time]);

  const handleLink = () => {
    const { current, pageSize, search, sort_name, sort_type, time } = listData;

    axios
      .get(`${name}/getLink`, {
        params: {
          ...form.getFieldsValue(),
          current,
          pageSize,
          username: form.getFieldValue('name'),
          ...search,
          sort_name,
          sort_type,
        },
      })
      .then((res) => {
        const { list, name: _name, current, pageSize, total } = res;
        setListData((e) => ({
          ...e,
          current,
          pageSize,
          list,
          total,
          loading: false,
        }));
        form.setFieldsValue({
          name: _name,
        });
        localStorage.setItem(
          `${name}_form`,
          JSON.stringify(form.getFieldsValue())
        );
        localStorage.setItem(`${name}_url_history1`, JSON.stringify(listData));
      })
      .catch((e) => {
        setListData((e) => ({
          ...e,
          list: [],
          loading: false,
        }));
      });
  };

  return (
    <BaseView name={name}>
      <Card
        title={`操作-今日更新 ${updateCount} 份问卷`}
        extra={
          <Space>
            {/* <div style={{ lineHeight: '36px', height: '36px' }}>
              <span>IP:{ipInfo?.ip}</span>&nbsp;&nbsp;
              <span>城市:{ipInfo?.city}</span>&nbsp;&nbsp;
              <span>地区:{ipInfo?.region}</span>&nbsp;&nbsp;
              <span>国家:{ipInfo?.country}</span>&nbsp;&nbsp;
              <Typography.Paragraph
                copyable={{ text: ipInfo?.postal }}
                style={{ display: 'inline-block' }}
              >
                邮编:{ipInfo?.postal}
              </Typography.Paragraph>
            </div> */}
            <Button
              key='zip1'
              type='link'
              onClick={() => {
                window.open('https://www.top10vpn.com/tools/what-is-my-ip/');
              }}
            >
              ZIP1
            </Button>
            <Button
              key='zip2'
              type='link'
              onClick={() => {
                window.open('https://www.showmyip.com/');
              }}
            >
              ZIP2
            </Button>
            <Button
              onClick={() => {
                axios
                  .get(`https://elr.point-survey.com/api/${name}/getIp`)
                  .then((res) => {
                    message.success(res);
                  })
                  .then(() => {
                    /* fetch('https://ipinfo.io/json?token=7bfff1ab831355')
                      .then((response) => response.json())
                      .then((jsonResponse) => {
                        const { postal } = jsonResponse;
                        setIpInfo(jsonResponse);
                        form.setFieldsValue({
                          email: postal,
                        });
                      }); */
                  });
              }}
            >
              检测IP
            </Button>
            {/* <Form
              onFinish={() => {
                form.setFieldsValue({
                  name: '',
                });
                localStorage.setItem(
                  `${name}_form`,
                  JSON.stringify(form.getFieldsValue())
                );
                setListData((e) => ({
                  ...e,
                  time: new Date().valueOf(),
                }));
              }}
              style={{ height: '32px' }}
            >
              <Space>
                <Form.Item>
                  <Button htmlType='submit' type='primary'>
                    获取问卷
                  </Button>
                </Form.Item>
              </Space>
            </Form> */}
          </Space>
        }
      >
        <Form
          form={form}
          onFinish={async (a) => {
            /* await axios.post(`${name}/addName`, a);*/
            form.setFieldsValue({
              name: '',
            });
            localStorage.setItem(
              `${name}_form`,
              JSON.stringify(form.getFieldsValue())
            );
            setListData((e) => ({
              ...e,
              time: new Date().valueOf(),
            }));
          }}
          initialValues={{
            name: ' ',
            sex: 0,
          }}
          onChange={() => {
            localStorage.setItem(
              `${name}_form`,
              JSON.stringify(form.getFieldsValue())
            );
          }}
        >
          <Form.Item name='name' label='账号' rules={[{ required: true }]}>
            <Input
              readOnly
              addonAfter={
                <div
                  onClick={() => {
                    copy(form.getFieldValue('name'));
                    message.success('已复制');
                  }}
                >
                  复制
                </div>
              }
            />
          </Form.Item>
          {/* <Form.Item name='age' label='年龄' rules={[{ required: true }]}>
            <InputNumber min={0} max={200} />
          </Form.Item>
          <Form.Item name='sex' label='性别' rules={[{ required: true }]}>
            <Select>
              <Select.Option value={0}>男</Select.Option>
              <Select.Option value={1}>女</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name='email' label='邮编' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name='work' label='就业状态' rules={[{ required: true }]}>
            <Select
              options={[
                '全职工作',
                '受雇兼职',
                '个体经营的',
                '失业的',
                '学生',
                '家庭主妇',
                '已退休的',
                '其他类型的有偿工作',
              ].map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.Item>
          <Form.Item name='marry' label='婚姻状态' rules={[{ required: true }]}>
            <Select
              options={[
                '单身（未婚）',
                '同居伴侣关系',
                '已婚',
                '分居/离婚/丧偶',
              ].map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.Item>
          <Form.Item name='position' label='职位' rules={[{ required: true }]}>
            <Select
              options={[
                'C 级（例如 CEO、CFO）、所有者、合伙人、总裁',
                '副总裁（执行副总裁、高级副总裁、副总裁、副总裁）',
                '董事（集团董事、高级董事、董事）',
                '经理（集团经理、高级经理、经理、项目经理）',
                '分析师',
                '助理或助理',
                '行政（文员或支持人员）',
                '顾问',
                '实习生',
                '志愿者',
                '以上都不是',
              ].map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.Item> */}
          <Form.Item>
            <Button htmlType='submit' type='primary'>
              获取问卷
            </Button>
          </Form.Item>
        </Form>
        <Form
          form={form2}
          onValuesChange={(e) => {
            setListData((a) => ({
              ...a,
              search: {
                ...a.search,
                ...e,
              },
              time: new Date().valueOf(),
            }));
          }}
        >
          <Form.Item label='ID' name='project_id'>
            <Input />
          </Form.Item>
          <Form.Item label='国家' name='country'>
            <Input />
          </Form.Item>
        </Form>
        <div style={{ minHeight: '1000px' }}>
          <Table
            loading={listData.loading}
            dataSource={listData.list}
            pagination={{
              pageSize: listData.pageSize,
              total: listData.total,
              current: listData.current,
            }}
            onChange={({ current, pageSize }, filter, { field, order }) => {
              if (current) {
                setListData((e) => ({
                  ...e,
                  current,
                }));
              }
              if (pageSize) {
                setListData((e) => ({
                  ...e,
                  pageSize,
                }));
              }
              if (field)
                setListData((e) => ({
                  ...e,
                  sort_name: field,
                }));
              if (order)
                setListData((e) => ({
                  ...e,
                  sort_type: order == 'ascend' ? 'asc' : 'desc',
                }));

              setListData((e) => ({
                ...e,
                time: new Date().valueOf(),
              }));
            }}
            rowKey={(record) => record.project_id}
            columns={[
              {
                title: 'ID',
                dataIndex: 'project_id',
              },
              {
                title: '名称',
                dataIndex: 'name',
              },
              {
                title: '剩余数',
                dataIndex: 'remaining_completes',
                sorter: true,
              },
              {
                title: '今日完成',
                dataIndex: 'num',
                sorter: true,
              },
              {
                title: '通过率',
                dataIndex: 'ir',
                sorter: true,
              },
              {
                title: '地区/国家',
                dataIndex: 'country',
              },
              {
                title: '金额',
                dataIndex: 'cpi',
                sorter: {
                  compare: (a, b) => a.cpi - b.cpi,
                  multiple: 1,
                },
              },
              {
                title: '完成次数',
                dataIndex: 'user_num',
                render: (v) => `${v || 0}/10`,
              },
              {
                title: '时间',
                dataIndex: 'itime',
              },
              {
                title: '操作',
                render: (value, record) => (
                  <Space>
                    {record.msg_type === 0 ? (
                      <Popover content={record.msg} title='举报内容'>
                        <Button
                          type='primary'
                          onClick={() => {
                            window.open(record.entry_link);
                          }}
                          danger
                        >
                          打开
                        </Button>
                      </Popover>
                    ) : record.msg_type === 1 ? (
                      <Popover content={record.msg} title='注释内容'>
                        <Button
                          type='primary'
                          onClick={() => {
                            window.open(record.entry_link);
                          }}
                          style={{
                            backgroundColor: '#52c41a',
                            borderColor: '#52c41a',
                          }}
                        >
                          打开
                        </Button>
                      </Popover>
                    ) : (
                      <Button
                        type='primary'
                        onClick={() => {
                          window.open(record.entry_link);
                        }}
                      >
                        打开
                      </Button>
                    )}

                    <Dropdown.Button
                      onClick={() => {
                        window.open(
                          '/api/notify/el/pe?id=' + record.project_id
                        );
                      }}
                      trigger={['click']}
                      overlay={
                        <Menu>
                          <Menu.Item
                            key='copy'
                            onClick={() => {
                              copy(record.entry_link);
                              message.success('复制成功');
                            }}
                          >
                            复制
                          </Menu.Item>
                          <Popover
                            key='report'
                            trigger='click'
                            content={
                              <Form
                                layout='inline'
                                initialValues={{
                                  el_links_project_id: record.project_id,
                                  msg: '',
                                }}
                                onFinish={(e) => {
                                  axios
                                    .post(
                                      `https://elr.point-survey.com/api/${name}/report`,
                                      { ...e, type: 0 }
                                    )
                                    .then(() => {
                                      message.success('提交成功');
                                    });
                                }}
                              >
                                <Form.Item name='el_links_project_id' hidden>
                                  <Input />
                                </Form.Item>
                                <Form.Item name='msg'>
                                  <Input placeholder='请输入举报内容' />
                                </Form.Item>
                                <Form.Item>
                                  <Button htmlType='submit'>提交</Button>
                                </Form.Item>
                              </Form>
                            }
                            title='举报'
                          >
                            <Menu.Item>举报</Menu.Item>
                          </Popover>
                          <Popover
                            key='report'
                            trigger='click'
                            content={
                              <Form
                                layout='inline'
                                initialValues={{
                                  el_links_project_id: record.project_id,
                                  msg: '',
                                }}
                                onFinish={(e) => {
                                  axios
                                    .post(
                                      `https://elr.point-survey.com/api/${name}/report`,
                                      { ...e, type: 1 }
                                    )
                                    .then(() => {
                                      message.success('提交成功');
                                    });
                                }}
                              >
                                <Form.Item name='el_links_project_id' hidden>
                                  <Input />
                                </Form.Item>
                                <Form.Item name='msg'>
                                  <Input placeholder='请输入注释内容' />
                                </Form.Item>
                                <Form.Item>
                                  <Button htmlType='submit'>提交</Button>
                                </Form.Item>
                              </Form>
                            }
                            title='注释'
                          >
                            <Menu.Item>注释</Menu.Item>
                          </Popover>
                        </Menu>
                      }
                    >
                      配额
                    </Dropdown.Button>
                  </Space>
                ),
              },
            ]}
          />
        </div>
      </Card>
      <Card title='历史完成问卷'>
        <Table
          dataSource={historyData}
          columns={[{ dataIndex: 'tname', title: '问卷id' }]}
        />
      </Card>
    </BaseView>
  );
}
export default El;
