import { Card, Radio, Col, Row, Statistic } from 'antd';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
function Info({ name }) {
  const defaultInfo = {
    month: { nums: '***', money: '***' },
    day: { nums: '***', money: '***' },
    yesterday: { nums: '***', money: '***' },
    avg: { day: '***', month: '***' },
  };
  const hiddenChar = '***';
  const span = name === 'lucid' ? 4 : 6;

  const [infoForm, setInfoForm] = useState('0');
  const [info, setInfo] = useState({
    ...defaultInfo,
  });

  const handleInfo = () => {
    setInfo({
      ...defaultInfo,
    });
    axios.get('common/getInfo', { params: { type: infoForm } }).then((res) => {
      const { month, day, avg, yesterday } = res;
      setInfo({ month, day, avg, yesterday });
    });
  };

  useEffect(() => {
    handleInfo();
  }, [infoForm]);

  return (
    <>
      <Card
        title='信息'
        extra={
          <Radio.Group
            defaultValue={infoForm}
            optionType='button'
            buttonStyle='solid'
            options={[
              { label: '我的', value: '0' },
              { label: '团队', value: '1' },
            ]}
            onChange={(e) => {
              setInfoForm(e.target.value);
            }}
          />
        }
      >
        <Row gutter={[32, 32]}>
          <Col key='1' span={span}>
            <Statistic
              title='今日(份)'
              value={
                info.day.nums === hiddenChar ? hiddenChar : info.day.nums || 0
              }
            />
          </Col>
          <Col key='2' span={span}>
            <Statistic
              title='今日(美元)'
              value={
                info.day.money === hiddenChar
                  ? hiddenChar
                  : Number(info.day.money || 0).toFixed(2)
              }
            />
          </Col>
          <Col key='3' span={span}>
            <Statistic
              title='本月(份)'
              value={
                info.month.nums === hiddenChar
                  ? hiddenChar
                  : info.month.nums || 0
              }
            />
          </Col>
          <Col key='4' span={span}>
            <Statistic
              title='本月(美元)'
              value={
                info.month.money === hiddenChar
                  ? hiddenChar
                  : Number(info.month.money || 0).toFixed(2)
              }
            />
          </Col>
          <Col key='5' span={span}>
            <Statistic
              title='昨日(份)'
              value={
                info?.yesterday?.nums === hiddenChar
                  ? hiddenChar
                  : info.yesterday.nums || 0
              }
            />
          </Col>
          <Col key='5' span={span}>
            <Statistic
              title='昨日(美元)'
              value={
                info?.yesterday?.money === hiddenChar
                  ? hiddenChar
                  : Number(info.yesterday.money || 0).toFixed(2)
              }
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
export default React.memo(Info);
